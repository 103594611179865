import {motion, AnimatePresence} from "framer-motion";

export const Slideshow = ({ image }) => (
    <AnimatePresence>
        <motion.img
            key={image}
            src={image}
            className={"absolute top-0 left-0 w-full h-full"}
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
        />
    </AnimatePresence>
)