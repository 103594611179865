import './App.css';
import {useEffect, useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {Slideshow} from "./SlideShow";

function App() {

    //states
    const [slide, setSlide] = useState(0)

    //array
    const slides = [
        '1.png',
        '2.png',
        '3.png',
        '4.png',
    ]

    useEffect(() => {
        const id = setInterval(() => {
            setSlide((oldCount) => (oldCount + 1) % slides.length)
        }, 3000);

        return () => {
            clearInterval(id);
        };
    }, []);

    return (
    <div className="h-full flex flex-col">
        <p className={"text-center text-black m-5 text-5xl heading"}>Nil Mallick</p>
        <div className={"my-10 grow flex flex-col justify-center"}>
            <div className={"bg-[#FFFAF6] rounded-lg shadow-lg shadow-red-500 aspect-video mx-10 md:mx-20 lg:mx-32 xl:mx-96 overflow-hidden relative"}>
                <Slideshow image={slides[slide]}/>
            </div>
        </div>
        <p className={"text-center text-black m-5 text-sm footer"}>mallick.nilotpal@gmail.com</p>
    </div>
  );
}

export default App;
